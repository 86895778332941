<template>
  <!--导出日志-->
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="8" :md="12">
                  <el-form-item label="导出时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.time"
                      type="daterange"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="4" :md="12">
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                    >查询
                    </el-button>
                    <el-button @click="reset">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>
      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
            </div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <template v-slot:operationSlot="{ scope }">
                <el-link
                  :underline="false"
                  type="warning"
                  v-if="scope.row.can_download == true"
                  @click="dowload(scope.row)"
                >下载</el-link>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
//引入配置
import setting from "@/config/setting";
// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import {export_download, export_history} from "@/api/yunli";

export default {
  // 组件生效
  components: {},
  data() {
    return {
      // 配置文件
      setting: setting,
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          prop: "firm_name",
          label: '归属方',
          isShow: true,
        },
        {
          prop: "title",
          label: '文件名称',
          isShow: true,
        },
        {
          label: "数据类型",
          prop: "type_label",
          isShow: true,
        },
        {
          label: "下载次数",
          prop: "download_count",
          isShow: true,
        },
        {
          label: "导出生成时间",
          prop: "export_time_str",
          isShow: true,
        },
        {
          label: "文件过期时间",
          prop: "expire_time_str",
          isShow: true,
        },
        {
          label: "文件状态",
          prop: "file_status",
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],
    };
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  methods: {
    /* 重置搜索 */
    reset() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },

    // 获取列表
    getList() {
      // this.loading = false
      export_history(this.where)
        .then(async (res) => {
          this.total = res.data.count;
          this.List = res.data.list;
          this.loading = false;
        })
        .catch((res) => {
          this.$Message.error(res.msg);
          this.loading = false;
        });
    },

    // 查询条件
    QueryList() {
      this.where.page = 1; // 当前页数
      this.where.limit = 10; // 每页显示条目个数
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val);
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection);
      // console.log("选择的所有数据行", selection);
      // console.log("当前选择的数据行", row);
    },
    ChangeSize(val) {
      // console.log("当前页面显示的条数", val);
      this.where.limit = val;
      this.getList();
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    //下载文件
    dowload(row){
      console.log(row)
      export_download({id: row.id, firm_id: row.firm_id}).then((res)=>{
        if(res.code==200){
          window.open(setting.apiBaseURL +'downloads?key='+ res.data.key);
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      })
    }

  },
};
</script>

<style lang="scss" scoped>
</style>
